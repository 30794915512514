import React from "react";
import { Form } from "@remix-run/react";
import { Input } from "~/components/catalyst/input";
import clsx from "clsx";
import { Button } from "../../marketing-site/components/Button";
import { STRIPE_STANDARD_PRICING_LOOKUP_KEY } from "~/billing/checkout/pricing-constants";


type PlanLinkType = "checkout-form" | "registration-link";

interface ProductDisplayProps {
  linkType: PlanLinkType;
}

export default function StripePricing(props: ProductDisplayProps) {
  return (<section className="w-full flex flex-col items-center justify-center">
    <Plan
      featured
      name="Monthly Plan"
      price="$15"
      linkType={props.linkType}
      stripePriceLookupKey={STRIPE_STANDARD_PRICING_LOOKUP_KEY}
      description="Free for 14 days, and then $15/month"
      features={[
        "No credit card needed upfront for the trial",
        "Automated daily check-in reminder texts",
        "Automated daily check-in calls",
        "Check in by text or call",
        "Automated alerts to emergency contacts"
      ]}>
    </Plan>
  </section>);
}

function Plan({
                name,
                linkType,
                price,
                description,
                features,
                stripePriceLookupKey,
                featured = false
              }: {
  name: string
  linkType: PlanLinkType,
  price: string
  description: string
  features: string[]
  stripePriceLookupKey: string,
  featured?: boolean
}) {
  return (
    <section
      className={clsx(
        "flex flex-col rounded-3xl px-6 sm:px-8",
        featured ? "order-first bg-blue-600 py-8 lg:order-none" : "lg:py-8"
      )}
    >
      <h3 className="mt-5 font-display text-lg text-white">{name}</h3>
      <p
        className={clsx(
          "mt-2 text-base",
          featured ? "text-white" : "text-slate-400"
        )}
      >
        {description}
      </p>
      <p className="order-first font-display text-5xl font-light tracking-tight text-white">
        {price}
      </p>
      <p className="order-first font-display text-1xl font-light tracking-tight text-white italic">
        billed monthly
      </p>
      <ul
        role="list"
        className={clsx(
          "order-last mt-10 flex flex-col gap-y-3 text-sm",
          featured ? "text-white" : "text-slate-200"
        )}
      >
        {features.map((feature) => (
          <li key={feature} className="flex">
            <CheckIcon className={featured ? "text-white" : "text-slate-400"} />
            <span className="ml-4">{feature}</span>
          </li>
        ))}
      </ul>

      {linkType === "registration-link" &&
        <Button
          href="/sign-up"
          variant={featured ? "solid" : "outline"}
          color="white"
          className="mt-8"
          aria-label={`Get started with the ${name} plan for ${price}`}
        >
          Get started
        </Button>
      }

      {linkType === "checkout-form" &&
        <Form className="mt-8" action="/create-checkout-session" method="POST">
          {/* Add a hidden field with the lookup_key of your Price */}
          <Input type="hidden" name="lookup_key" value={stripePriceLookupKey} />
          <Button
            type="submit"
            data-umami-event="Get Started Button"
            data-umami-event-location="pricing"
            // variant={featured ? "solid" : "outline"}
            color="white"
            className="w-full"
            aria-label={`Get started with the ${name} plan for ${price}`}
          >
            Checkout
          </Button>
        </Form>}
    </section>
  );
}

function CheckIcon({
                     // eslint-disable-next-line react/prop-types
                     className,
                     ...props
                   }: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      aria-hidden="true"
      className={clsx(
        "h-6 w-6 flex-none fill-current stroke-current",
        className
      )}
      {...props}
    >
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        strokeWidth={0}
      />
      <circle
        cx={12}
        cy={12}
        r={8.25}
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

